/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { translations } from "../../config/translations";
import { post, get } from "../../utils/api";
import { Form, Input } from "antd";
import { formLayoutDrawer } from "../../helpers/TableHelper";
import { DrawerButtons } from "../../components/drawer/buttons/DrawerButtons";
import { showSuccess } from "../../helpers/NotificationHelper";
import { StringResponse } from "../../core/models/response/StringResponse";
import { kodValidationHelper } from "../../helpers/FormValidator";
import { BooleanResponse } from "../../core/models/response/BooleanResponse";
interface Props {
  onClose: () => void;
  onSuccess: () => void;
  restoranId?: number;
}

const KodForm: React.FC<Props> = (props: Props) => {
  const { onClose, onSuccess, restoranId } = props;
  const [kod, setKod] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getKod();
  }, []);

  const getKod = async () => {
    setLoading(true);

    const data: StringResponse = await get(
      "/User/GetKod/" + restoranId,
      translations.kod.errorGet
    );

    if (data) {
      setKod(data.value);
    }

    setLoading(false);
  };

  const handleSave = async () => {
    let formData = new FormData();

    formData.append("kod", kod!);
    formData.append("restoranId", restoranId as any);

    const data: BooleanResponse = await post(
      "/Restoran/EditKod",
      formData,
      translations.kod.errorEdit
    );

    if (data.value) {
      showSuccess(translations.kod.successEdit);
      onSuccess();
    }
  };

  return (
    <>
      {((!loading && kod) || !kod) && (
        <Form
          onFinish={handleSave}
          style={{ marginLeft: "20px", marginRight: "40px" }}
          {...formLayoutDrawer}
        >
          <DrawerButtons onCancelAction={() => onClose()} />

          <Form.Item
            name="kod"
            label={translations.kod.kod}
            initialValue={kod}
            rules={[
              {
                required: true,
                message: translations.general.requiredField,
              },
              {
                required: true,
                validator: (_rule: any, value: any, callback: any): void =>
                  kodValidationHelper(
                    _rule,
                    callback,
                    translations.kod.kodValidator,
                    kod
                  ),
              },
            ]}
          >
            <Input
              placeholder={translations.kod.unesiteKod}
              value={kod}
              onChange={(input): void => {
                setKod(
                  input.target.value.length > 0 ? input.target.value : undefined
                );
              }}
            ></Input>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default KodForm;
