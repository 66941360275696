/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { translations } from "../../config/translations";
import { post, get, deleteApi } from "../../utils/api";
import { Form, Input, Modal, InputNumber } from "antd";
import { formLayoutDrawer } from "../../helpers/TableHelper";
import { DrawerButtons } from "../../components/drawer/buttons/DrawerButtons";
import { showSuccess } from "../../helpers/NotificationHelper";
import { DropdownOption } from "../../core/models/dropdown/DropdownOption";
import { AktivanEnum } from "../../core/models/enum/enum";
import { AutocompleteDropdown } from "../../components/autocomplete-dropdown/AutocompleteDropdown";
import {
  filterDropdownOptions,
  confirmDropdownHelper,
} from "../../helpers/AutocompleteDropdownHelper";
import { ArtiklEditResponse } from "../../core/models/response/ArtiklEditResponse";
import { ArtiklEditRequest } from "../../core/models/request/ArtiklEditRequest";
import { RestoranValutaResponse } from "../../core/models/response/RestoranValutaResponse";
import { RestoranJezikResponse } from "../../core/models/response/RestoranJezikResponse";
import { customArrayValidationHelper } from "../../helpers/TableHelper";
import { BooleanResponse } from "../../core/models/response/BooleanResponse";

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  artiklId?: number;
  skupineDropdownOptions?: DropdownOption[];
  aktivanDropdownOptions?: DropdownOption[];
  restoranValuta: RestoranValutaResponse;
  restoranJezik: RestoranJezikResponse;
}

const ArtiklForm: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    onSuccess,
    artiklId,
    skupineDropdownOptions,
    aktivanDropdownOptions,
    restoranJezik,
    restoranValuta,
  } = props;
  const [naziv, setNaziv] = useState<string | undefined>();
  const [nazivEng, setNazivEng] = useState<string | undefined>();
  const [opis, setOpis] = useState<string | undefined>();
  const [opisEng, setOpisEng] = useState<string | undefined>();
  const [cijenaHRK, setCijenaHRK] = useState<number | undefined>();
  const [cijenaEUR, setCijenaEUR] = useState<number | undefined>();
  const [sortOrder, setSortOrder] = useState<string>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedSkupina, setSelectedSkupina] = useState<
    DropdownOption[] | undefined
  >();
  const [selectedAktivan, setSelectedAktivan] = useState<
    DropdownOption[] | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);
  const [initialSkupina, setInitialSkupina] = useState<DropdownOption[]>();

  const { TextArea } = Input;

  useEffect(() => {
    if (artiklId) {
      setIsEdit(true);
      getArtikl();
    }
  }, [artiklId]);

  const getArtikl = async () => {
    setLoading(true);

    const data: ArtiklEditResponse = await get(
      "/Artikl/GetById/" + artiklId,
      translations.artikl.errorGet
    );

    if (data) {
      setNaziv(data.naziv);
      setNazivEng(data.nazivEng);
      setOpis(data.opis);
      setOpisEng(data.opisEng);
      setCijenaHRK(data.cijenaHRK);
      setCijenaEUR(data.cijenaEUR);
      setSortOrder(data.sortOrder);
      const skupina = getSkupina(data.skupinaId!)
      setSelectedSkupina(skupina);
      setInitialSkupina(skupina);
      setSelectedAktivan(
        getAktivan(data.aktivan ? AktivanEnum.DA : AktivanEnum.NE)
      );
    }

    setLoading(false);
  };

  const getAktivan = (id: AktivanEnum): DropdownOption[] => {
    const aktivan: DropdownOption[] = aktivanDropdownOptions!.filter(
      (o: DropdownOption): boolean => Number(o.id!) === id!
    );

    return aktivan;
  };

  const getSkupina = (id: number): DropdownOption[] => {
    const skupinaId: DropdownOption[] = skupineDropdownOptions!.filter(
      (o: DropdownOption): boolean => Number(o.id!) === id!
    );

    return skupinaId;
  };

  const handleSave = async () => {
    const artikl: ArtiklEditRequest = {
      id: artiklId,
      skupinaId: Number(selectedSkupina![0].id),
      naziv,
      nazivEng,
      opis,
      opisEng,
      cijenaHRK,
      cijenaEUR,
      sortOrder,
      aktivan: Number(selectedAktivan![0].id) === AktivanEnum.DA ? true : false,
    };

    if (!isEdit) {
      const data: BooleanResponse = await post(
        "/Artikl/Save",
        artikl,
        translations.artikl.errorCreate
      );

      if (data.value) {
        showSuccess(translations.artikl.successCreate);
        onSuccess();
      }
    } else {
      const data: BooleanResponse = await post(
        "/Artikl/Edit",
        artikl,
        translations.artikl.errorEdit
      );

      if (data.value) {
        showSuccess(translations.artikl.successEdit);
        onSuccess();
      }
    }
  };

  const confirmAktivan = (option: DropdownOption[]) => {
    const selectedAktivan = confirmDropdownHelper(option);

    setSelectedAktivan(selectedAktivan);
  };

  const confirmSkupina = (option: DropdownOption[]) => {
    const selectedSkupina = confirmDropdownHelper(option);

    setSelectedSkupina(selectedSkupina);
  };

  const handleDeleteModal = (newState: boolean) => {
    setShowDeleteModal(newState);
  };

  const deleteArtikl = async () => {
    const data: BooleanResponse = await deleteApi(
      "/Artikl/Delete/" + artiklId,
      translations.artikl.errorDelete
    );

    if (data.value) {
      showSuccess(translations.artikl.successDelete);
      onSuccess();
    }
  };

  return (
    <>
      {((!loading && artiklId) || !artiklId) && (
        <Form
          onFinish={handleSave}
          style={{ marginLeft: "20px", marginRight: "40px" }}
          {...formLayoutDrawer}
        >
          <DrawerButtons
            onCancelAction={() => onClose()}
            isEdit={isEdit}
            onDeleteAction={() => handleDeleteModal(true)}
          />
            <Form.Item
              name="skupineDropdown"
              label={translations.artikl.skupina}
              rules={[
                {
                  required: true,
                  validator: (_rule: any, value: any, callback: any): void =>
                    customArrayValidationHelper(
                      _rule,
                      callback,
                      translations.general.requiredField,
                      selectedSkupina
                    ),
                },
              ]}
            >
              <AutocompleteDropdown
                placeholder={translations.artikl.unesiteSkupina}
                getOptionsFrontend={(value: string): DropdownOption[] =>
                  filterDropdownOptions(value, skupineDropdownOptions!)
                }
                initialValues={selectedSkupina}
                confirmDirty={confirmSkupina}
              />
            </Form.Item>
          <Form.Item
            name="naziv"
            label={translations.artikl.naziv}
            initialValue={naziv}
            rules={[
              {
                required: true,
                message: translations.general.requiredField,
              },
            ]}
          >
            <TextArea
              autoSize={{ minRows: 1 }}
              placeholder={translations.artikl.unesiteNaziv}
              value={naziv}
              onChange={(input): void => {
                setNaziv(
                  input.target.value.length > 0 ? input.target.value : undefined
                );
              }}
            ></TextArea>
          </Form.Item>
          {restoranJezik.eng && (
            <Form.Item
              name="nazivEng"
              label={translations.artikl.nazivEng}
              initialValue={nazivEng}
              rules={[
                {
                  required: restoranJezik.eng,
                  message: translations.general.requiredField,
                },
              ]}
            >
              <TextArea
                autoSize={{ minRows: 1 }}
                placeholder={translations.artikl.unesiteNazivEng}
                value={nazivEng}
                onChange={(input): void => {
                  setNazivEng(
                    input.target.value.length > 0
                      ? input.target.value
                      : undefined
                  );
                }}
              ></TextArea>
            </Form.Item>
          )}
          <Form.Item
            name="opis"
            label={translations.artikl.opis}
            initialValue={opis}
          >
            <TextArea
              autoSize={{ minRows: 1 }}
              placeholder={translations.artikl.unesiteOpis}
              value={opis}
              onChange={(input): void => {
                setOpis(
                  input.target.value.length > 0 ? input.target.value : undefined
                );
              }}
            ></TextArea>
          </Form.Item>
          {restoranJezik.eng && (
            <Form.Item
              name="opisEng"
              label={translations.artikl.opisEng}
              initialValue={opisEng}
            >
              <TextArea
                autoSize={{ minRows: 1 }}
                placeholder={translations.artikl.unesiteOpisEng}
                value={opisEng}
                onChange={(input): void => {
                  setOpisEng(
                    input.target.value.length > 0
                      ? input.target.value
                      : undefined
                  );
                }}
              ></TextArea>
            </Form.Item>
          )}
          {restoranValuta.hrk && (
            <Form.Item
              name="cijena"
              label={translations.artikl.cijenaHRK}
              initialValue={cijenaHRK}
              rules={[
                {
                  required: restoranValuta.hrk,
                  message: translations.general.requiredField,
                },
              ]}
            >
              <InputNumber
                placeholder={translations.artikl.unesiteCijenaHRK}
                value={cijenaHRK}
                parser={(value) => Number(value?.replace(",", "."))}
                onChange={(input): void => {
                  setCijenaHRK(input ? input : undefined);
                }}
                style={{ width: "max-width" }}
                addonAfter="kn"
              ></InputNumber>
            </Form.Item>
          )}
          {restoranValuta.eur && (
            <Form.Item
              name="cijenaEUR"
              label={translations.artikl.cijenaEUR}
              initialValue={cijenaEUR}
              rules={[
                {
                  required: restoranValuta.eur,
                  message: translations.general.requiredField,
                },
              ]}
            >
              <InputNumber
                placeholder={translations.artikl.unesiteCijenaEUR}
                value={cijenaEUR}
                parser={(value) => Number(value?.replace(",", "."))}
                onChange={(input): void => {
                  setCijenaEUR(input ? input : undefined);
                }}
                style={{ width: "max-width" }}
                addonAfter="€"
              ></InputNumber>
            </Form.Item>
          )}
          {isEdit && initialSkupina === selectedSkupina && (
            <Form.Item
              name="sortOrder"
              label={translations.artikl.sortOrder}
              initialValue={sortOrder}
              rules={[
                {
                  required: isEdit,
                  message: translations.general.requiredField,
                },
              ]}
            >
              <InputNumber
                min={"1"}
                value={sortOrder}
                onChange={(input): void => {
                  setSortOrder(input);
                }}
                disabled={initialSkupina !== selectedSkupina}
              ></InputNumber>
            </Form.Item>
          )}
          <Form.Item
            name="aktivanDropdown"
            label={translations.artikl.aktivan}
            rules={[
              {
                required: true,
                validator: (_rule: any, value: any, callback: any): void =>
                  customArrayValidationHelper(
                    _rule,
                    callback,
                    translations.general.requiredField,
                    selectedAktivan
                  ),
              },
            ]}
          >
            <AutocompleteDropdown
              placeholder={translations.artikl.unesiteAktivan}
              getOptionsFrontend={(value: string): DropdownOption[] =>
                filterDropdownOptions(value, aktivanDropdownOptions!)
              }
              initialValues={selectedAktivan}
              confirmDirty={confirmAktivan}
            />
          </Form.Item>
          {isEdit && (
            <Modal
              title={translations.artikl.deleteModal}
              visible={showDeleteModal}
              onOk={deleteArtikl}
              onCancel={(): void => handleDeleteModal(false)}
              okText={translations.general.modalOk}
              cancelText={translations.general.modalCancel}
              width={320}
            >
              {translations.artikl.deleteModalText}
            </Modal>
          )}
        </Form>
      )}
    </>
  );
};

export default ArtiklForm;
