/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ReactElement } from "react";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { Form, Spin, Row, Col, Typography } from "antd";
import ReactHtmlParser from "react-html-parser";
import { ArtiklTable } from "../../../core/models/dtos/ArtiklTable";
import Table, { ColumnsType } from "antd/lib/table";
import { get } from "../../../utils/api";
import { getTableLocale } from "../../../helpers/TableHelper";
import { Image } from "antd";
import { SkupinaTable } from "../../../core/models/dtos/SkupinaTable";
import { JezikEnum, ValutaEnum } from "../../../core/models/enum/enum";
import { DropdownOption } from "../../../core/models/dropdown/DropdownOption";
import {
  filterDropdownOptions,
  confirmDropdownHelper,
} from "../../../helpers/AutocompleteDropdownHelper";
import { translations } from "../../../config/translations";
import { AutocompleteDropdown } from "../../../components/autocomplete-dropdown/AutocompleteDropdown";
import { AlignType } from "rc-table/lib/interface";
import Grid from "antd/lib/card/Grid";

interface Props {
  jezikDropdownOptions: DropdownOption[];
  valuteDropdownOptions: DropdownOption[];
  restoranId: number;
  logo: string;
  restoranHtml?: string;
  path: string;
}

const MainForm: React.FC<Props> = (props: Props) => {
  const {
    jezikDropdownOptions,
    valuteDropdownOptions,
    restoranId,
    logo,
    restoranHtml,
    path,
  } = props;
  const [artikli, setArtikli] = useState<ArtiklTable[] | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [skupine, setSkupine] = useState<SkupinaTable[] | undefined>();
  const [selectedJezik, setSelectedJezik] = useState<
    DropdownOption[] | undefined
  >();
  const [selectedValuta, setSelectedValuta] = useState<
    DropdownOption[] | undefined
  >();

  const jezikId = JezikEnum.HRV;
  const valutaId = ValutaEnum.EUR;

  const { Text } = Typography;

  useEffect(() => {
    setInitialJezik(JezikEnum.HRV);
    setInitialValuta(ValutaEnum.EUR);
  }, []);

  useEffect(() => {
    if (selectedJezik && selectedValuta) getArtikli();
  }, [selectedJezik, selectedValuta]);

  const getArtikli = async () => {
    setLoading(true);

    let skupineData: SkupinaTable[];

    if (selectedJezik === undefined) {
      skupineData = await get(
        "/Skupina/GetAllActive/" + restoranId + "/" + jezikId
      );
    } else {
      skupineData = await get(
        "/Skupina/GetAllActive/" +
          restoranId +
          "/" +
          Number(selectedJezik![0].id)
      );
    }
    if (skupineData && skupineData.length > 0) {
      setSkupine(skupineData);
    } else {
      setSkupine(undefined);
    }

    let artiklData: ArtiklTable[];

    if (selectedJezik === undefined && selectedValuta === undefined) {
      artiklData = await get(
        "/Artikl/GetAllActive/" + jezikId + "/" + valutaId + "/" + restoranId
      );
    } else if (selectedJezik === undefined) {
      artiklData = await get(
        "/Artikl/GetAllActive/" + jezikId + "/" + Number(selectedValuta![0].id)
      );
    } else if (selectedValuta === undefined) {
      artiklData = await get(
        "/Artikl/GetAllActive/" +
          Number(selectedJezik![0].id) +
          "/" +
          valutaId +
          "/" +
          restoranId
      );
    } else {
      artiklData = await get(
        "/Artikl/GetAllActive/" +
          Number(selectedJezik![0].id) +
          "/" +
          Number(selectedValuta![0].id) +
          "/" +
          restoranId
      );
    }

    if (artiklData && artiklData.length > 0) {
      setArtikli(artiklData);
    } else {
      setArtikli(undefined);
    }

    setLoading(false);
  };

  const setInitialJezik = (lang: JezikEnum): void => {
    const jezik: DropdownOption[] = jezikDropdownOptions.filter(
      (o: DropdownOption): boolean => Number(o.id!) === lang!
    );

    confirmJezik(jezik);
  };

  const setInitialValuta = (val: ValutaEnum): void => {
    const valuta: DropdownOption[] = valuteDropdownOptions.filter(
      (o: DropdownOption): boolean => Number(o.id!) === val!
    );

    confirmValuta(valuta);
  };

  const confirmJezik = (option: DropdownOption[]) => {
    const jezik = confirmDropdownHelper(option);

    if (jezik !== selectedJezik) {
      setSelectedJezik(jezik);
    }
  };

  const confirmValuta = (option: DropdownOption[]) => {
    const valuta = confirmDropdownHelper(option);

    if (valuta !== selectedValuta) {
      setSelectedValuta(valuta);
    }
  };

  const getColumns = (naziv?: string) => {
    const columns: ColumnsType<ArtiklTable> = [
      {
        key: "naziv",
        title: naziv,
        dataIndex: "naziv",
        width: "72%",
      },
      {
        key: "cijena",
        dataIndex: "cijena",
        width: "28%",
        align: "end" as AlignType,
        className: "cijenaColumn",
      },
    ];

    return columns;
  };

  return (
    <>
      <Grid className={`${path}-grid`}>
        <Row style={{ marginBottom: "20px" }}>
          {valuteDropdownOptions.length > 1 && (
            <Col span={14} style={{ textAlign: "right", marginTop: "15px" }}>
              {skupine && artikli && (
                <>
                  <Text>
                    {selectedValuta &&
                    selectedValuta[0].id === ValutaEnum.EUR.toString()
                      ? translations.general.valuta
                      : translations.general.valutaEng}
                  </Text>
                  <AutocompleteDropdown
                    getOptionsFrontend={(value: string): DropdownOption[] =>
                      filterDropdownOptions(value, valuteDropdownOptions!)
                    }
                    initialValues={selectedValuta}
                    confirmDirty={confirmValuta}
                    style={{ width: "70px", marginLeft: "10px" }}
                    placeholder={translations.general.valuta}
                  />
                </>
              )}
            </Col>
          )}
          {jezikDropdownOptions.length > 1 && (
            <Col
              span={valuteDropdownOptions.length > 1 ? 9 : 23}
              style={{ textAlign: "right", marginTop: "15px", width: "200px" }}
            >
              {skupine && artikli && (
                <>
                  <Text className={`${path}-header-text`}>
                    {selectedJezik &&
                    selectedJezik[0].id === JezikEnum.HRV.toString()
                      ? translations.general.jezik
                      : translations.general.jezikEng}
                  </Text>
                  <AutocompleteDropdown
                    getOptionsFrontend={(value: string): DropdownOption[] =>
                      filterDropdownOptions(value, jezikDropdownOptions!)
                    }
                    initialValues={selectedJezik}
                    confirmDirty={confirmJezik}
                    style={{ width: "80px", marginLeft: "10px" }}
                    placeholder={translations.general.jezik}
                  />
                </>
              )}
            </Col>
          )}
        </Row>
        <Form className={`${path}-ant-form`}>
          <Form.Item>
            <Image
              src={logo}
              className={`${path}-logo-header`}
              preview={false}
            />
          </Form.Item>
          {!artikli && (
            <Spin
              size="large"
              spinning={loading}
              style={{ marginTop: "100px", marginLeft: "calc(50vw - 10px)" }}
            />
          )}
          {skupine &&
            artikli &&
            skupine.map(
              (skupina: SkupinaTable): ReactElement => (
                <Form.Item key={skupina.id} name={skupina.id}>
                  {artikli.filter(
                      (a: ArtiklTable): boolean => a.skupinaId === skupina.id
                    ).length > 0 && 
                  <Table
                    key={skupina.id}
                    locale={getTableLocale()}
                    dataSource={artikli.filter(
                      (a: ArtiklTable): boolean => a.skupinaId === skupina.id
                    )}
                    columns={getColumns(
                      skupine.filter(
                        (s: SkupinaTable): boolean => s.id === skupina.id
                      )[0].naziv
                    )}
                    rowKey={(record) => record.id!}
                    expandable={{
                      expandedRowRender: (record) => (
                        <p
                          style={{
                            marginLeft: "46px",
                            marginRight: "75px",
                            fontStyle: "italic",
                          }}
                        >
                          {ReactHtmlParser(record.opis!)}
                        </p>
                      ),
                      rowExpandable: (record) => record.opis !== null,
                      expandIcon: ({ expanded, onExpand, record }) =>
                        expanded
                          ? record.opis !== null && (
                              <DownOutlined
                                onClick={(e) => onExpand(record, e)}
                              />
                            )
                          : record.opis !== null && (
                              <RightOutlined
                                onClick={(e) => onExpand(record, e)}
                              />
                            ),
                    }}
                    bordered={false}
                    pagination={false}
                    loading={loading}
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                      whiteSpace: "pre",
                    }}
                    rowClassName={(item, index) =>
                      index % 2 === 0 ? `${path}-row-light` : `${path}-row-dark`
                    }
                    className={path}
                  />
                }
                </Form.Item>
              )
            )}
          {skupine && artikli && restoranHtml && (
            <>
              <div style={{ width: "100vw", textAlign: "center" }}>
                {ReactHtmlParser(restoranHtml)}
              </div>
            </>
          )}
          {skupine && artikli && (
            <Form.Item>
              <Image
                src={logo}
                className={`${path}-logo-footer`}
                preview={false}
              />
            </Form.Item>
          )}
        </Form>
      </Grid>
    </>
  );
};

export default MainForm;
