import React, { useEffect, useState } from "react";
import { Route, withRouter } from "react-router";
import { Switch, RouteComponentProps } from "react-router-dom";
import { Layout as AntLayout } from 'antd';
import Home from "./components/Home";
import { translations } from "./config/translations";
import Routes from "./config/routes";
import Main from "./modules/main/Main";
import MainAdmin from "./modules/mainAdmin/MainAdmin";
import "antd/dist/antd.css";
import "./custom.css";

const App: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  useEffect((): void => {
    document.title = translations.general.dashboard;
  }, []);
            
  return (
    <AntLayout>             
      <Switch>
        <Route exact path={Routes.ROUTE_HOME} component={Home} />
        <Route exact path={Routes.ROUTE_POLOVANEC} component={Main} />
        <Route
          exact
          path={Routes.ROUTE_POLOVANEC_ADMIN}
          component={MainAdmin}
        />
        <Route exact path={Routes.ROUTE_TEST} component={Main} />
        <Route exact path={Routes.ROUTE_TEST_ADMIN} component={MainAdmin} />
        <Route exact path={Routes.ROUTE_SHOCK_BAR} component={Main} />
        <Route exact path={Routes.ROUTE_SHOCK_BAR_ADMIN} component={MainAdmin} />
      </Switch>
    </AntLayout>
  );
};

export default withRouter(App);
