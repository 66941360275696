import React, { useState } from "react";
import { translations } from "../../config/translations";
import { post } from "../../utils/api";
import { Form, Input, Button } from "antd";
import { formLayoutDrawer } from "../../helpers/TableHelper";
import { DrawerButtons } from "../../components/drawer/buttons/DrawerButtons";
import { showError, showSuccess } from "../../helpers/NotificationHelper";
import { LoginResponse } from "../../core/models/response/LoginResponse";

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  onForgotPassword: () => void;
  restoranId?: number;
  setMainAdmin: (newState: boolean) => void;
}

const LoginForm: React.FC<Props> = (props: Props) => {
  const { onClose, onSuccess, onForgotPassword, restoranId, setMainAdmin } =
    props;
  const [email, setEmail] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();

  const handleLogin = async () => {
    let formData = new FormData();

    formData.append("email", email!);
    formData.append("password", password!);
    formData.append("restoranId", restoranId as any);

    const data: LoginResponse = await post(
      "/User/Login",
      formData,
      translations.user.errorLogin
    );

    if (data.successful) {
      showSuccess(translations.user.successLogin);
      setMainAdmin(data.isMainAdmin!);
      onSuccess();
    } else {
      showError(translations.user.errorLogin);
    }
  };

  return (
    <Form
      onFinish={handleLogin}
      style={{ marginLeft: "20px", marginRight: "40px" }}
      {...formLayoutDrawer}
    >
      <DrawerButtons
        onCancelAction={() => onClose()}
        disableSubmit={!email || !password}
      />
      <Form.Item label={translations.user.email} style={{ marginTop: "30px" }}>
        <Input
          placeholder={translations.user.unesiteEmail}
          value={email}
          type="email"
          onChange={(input): void => {
            setEmail(input.target.value);
          }}
        ></Input>
      </Form.Item>
      <Form.Item label={translations.user.password}>
        <Input
          placeholder={translations.user.unesitePassword}
          value={password}
          type="password"
          onChange={(input): void => {
            setPassword(input.target.value);
          }}
        ></Input>
      </Form.Item>
      <Form.Item>
        <Button
          type="text"
          style={{
            display: "block",
            marginLeft: "50px",
            fontSize: "10px",
            textAlign: "center",
          }}
          onClick={() => onForgotPassword()}
        >
          {translations.user.forgotPassword}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
