/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { DropdownOption } from "../../core/models/dropdown/DropdownOption";
import MainForm from "./forms/MainForm";
import { get } from "../../utils/api";
import { getMainPath } from "../../helpers/URLHelper";
import { NumberResponse } from "../../core/models/response/NumberResponse";
import { Jezik } from "../../core/models/dtos/Jezik";
import { Valuta } from "../../core/models/dtos/Valuta";
import { getLogo } from "../../helpers/LogoHelper";
import { StringResponse } from "../../core/models/response/StringResponse";

const Main: React.FC = () => {
  const [jezikDropdownOptions, setJezikDropdownOptions] = useState<
    DropdownOption[]
  >([]);
  const [valuteDropdownOptions, setValuteDropdownOptions] = useState<
    DropdownOption[]
  >([]);
  const [restoranId, setRestoranId] = useState<number | undefined>();
  const [path, setPath] = useState<string | undefined>();
  const [logo, setLogo] = useState<string | undefined>();
  const [restaurantHtml, setRestaurantHtml] = useState<string | undefined>();

  useEffect(() => {
    getPath();
  }, []);

  useEffect(() => {
    path && getRestoranId();
  }, [path]);

  useEffect(() => {
    if (restoranId !== undefined) {
      getRestaurantLogo();
      getJezikDropdownOptions();
      getValutaDropdownOptions();
      getRestaurantHtml();
    }
  }, [restoranId]);

  const getPath = () => {
    var currentPath = getMainPath();

    currentPath && setPath(currentPath);
  };

  const getRestoranId = async () => {
    const data: NumberResponse = await get("/Restoran/GetIdByRoute/" + path);

    if (data) {
      setRestoranId(data.value);
    }
  };

  const getRestaurantHtml = async () => {
    const data: StringResponse = await get("/Restoran/GetHtml/" + restoranId);

    if (data) {
      setRestaurantHtml(data.value);
    }
  };

  const getRestaurantLogo = () => {
    var data = getLogo(restoranId!);

    setLogo(data);
  };

  const getJezikDropdownOptions = async () => {
    const data: Jezik[] = await get("/Jezik/GetAllByRestoranId/" + restoranId);

    if (data) {
      setJezikDropdownOptions(
        data.map(
          (item: Jezik): DropdownOption => ({
            id: item.id.toString(),
            name: item.kratkiNaziv,
          })
        )
      );
    }
  };

  const getValutaDropdownOptions = async () => {
    const data: Valuta[] = await get(
      "/Valuta/GetAllByRestoranId/" + restoranId
    );

    if (data) {
      setValuteDropdownOptions(
        data.map(
          (item: Valuta): DropdownOption => ({
            id: item.id.toString(),
            name: item.kratkiNaziv,
          })
        )
      );
    }
  };

  return (
    <Form className={`${path}-ant-form`}>
      {jezikDropdownOptions.length > 0 && valuteDropdownOptions.length > 0 && (
        <Form.Item>
          <MainForm
            jezikDropdownOptions={jezikDropdownOptions}
            valuteDropdownOptions={valuteDropdownOptions}
            restoranId={restoranId!}
            logo={logo!}
            restoranHtml={restaurantHtml!}
            path={path!}
          />
        </Form.Item>
      )}
    </Form>
  );
};

export default Main;
