/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { translations } from "../../config/translations";
import { Form, Drawer, Button, Modal, Spin } from "antd";
import { ArtiklAdminTable } from "../../core/models/dtos/ArtiklAdminTable";
import Table, { ColumnsType } from "antd/lib/table";
import { get, deleteApi, post } from "../../utils/api";
import { getTableLocale } from "../../helpers/TableHelper";
import { Image } from "antd";
import { SkupinaAdminTable } from "../../core/models/dtos/SkupinaAdminTable";
import { SkupinaDropdown } from "../../core/models/dtos/SkupinaDropdown";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { DrawerState } from "../../core/models/enum/enum";
import ArtiklForm from "../artikl/ArtiklForm";
import SkupinaForm from "../skupina/SkupinaForm";
import LoginForm from "../login/LoginForm";
import RegisterForm from "../register/RegisterForm";
import { DRAWER_WIDTH } from "../../config/constants";
import { onRowClick } from "../../helpers/TableHelper";
import { DropdownOption } from "../../core/models/dropdown/DropdownOption";
import { getAktivanModel } from "../../helpers/EnumHelper";
import { IAktivanModel } from "../../core/models/enum/IAktivanModel";
import { UserTable } from "../../core/models/dtos/UserTable";
import { showSuccess } from "../../helpers/NotificationHelper";
import { ReactElement } from "react";
import { AlignType } from "rc-table/lib/interface";
import KodForm from "../kod/KodForm";
import ChangePasswordForm from "../changePassword/ChangePasswordForm";
import { getAdminPath } from "../../helpers/URLHelper";
import { NumberResponse } from "../../core/models/response/NumberResponse";
import { getLogo } from "../../helpers/LogoHelper";
import { RestoranJezikResponse } from "../../core/models/response/RestoranJezikResponse";
import { RestoranValutaResponse } from "../../core/models/response/RestoranValutaResponse";
import { BooleanResponse } from "../../core/models/response/BooleanResponse";

const MainAdmin: React.FC = () => {
  const [artikli, setArtikli] = useState<ArtiklAdminTable[] | undefined>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [skupine, setSkupine] = useState<SkupinaAdminTable[] | undefined>([]);
  const [users, setUsers] = useState<UserTable[] | undefined>();
  const [shouldTableUpdate, setShouldTableUpdate] = useState(false);
  const [shouldSkupineTableUpdate, setShouldSkupineTableUpdate] =
    useState(false);
  const [artiklDrawerState, setArtiklDrawerState] = useState<DrawerState>(
    DrawerState.Closed
  );
  const [skupinaDrawerState, setSkupinaDrawerState] = useState<DrawerState>(
    DrawerState.Closed
  );
  const [loginDrawerState, setLoginDrawerState] = useState<DrawerState>(
    DrawerState.Closed
  );
  const [registerDrawerState, setRegisterDrawerState] = useState<DrawerState>(
    DrawerState.Closed
  );
  const [changePasswordDrawerState, setChangePasswordDrawerState] =
    useState<DrawerState>(DrawerState.Closed);
  const [kodDrawerState, setKodDrawerState] = useState<DrawerState>(
    DrawerState.Closed
  );
  const [clickedArtiklId, setClickedArtiklId] = useState<number>();
  const [clickedSkupinaId, setClickedSkupinaId] = useState<number>();
  const [clickedUserId, setClickedUserId] = useState<string>();
  const [mainAdmin, setMainAdmin] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [skupineDropdownOptions, setSkupineDropdownOptions] = useState<
    DropdownOption[]
  >([]);
  const [aktivanDropdownOptions, setAktivanDropdownOptions] = useState<
    DropdownOption[]
  >([]);
  const [userLoggedIn, setUserLoggedIn] = useState<boolean>(false);
  const [artiklActiveModal, setArtiklActiveModal] = useState<boolean>(false);
  const [skupinaActiveModal, setSkupinaActiveModal] = useState<boolean>(false);
  const [restoranId, setRestoranId] = useState<number | undefined>();
  const [path, setPath] = useState<string | undefined>();
  const [logo, setLogo] = useState<string | undefined>();
  const [restoranJezik, setRestoranJezik] = useState<
    RestoranJezikResponse | undefined
  >();
  const [restoranValuta, setRestoranValuta] = useState<
    RestoranValutaResponse | undefined
  >();

  useEffect(() => {
    getPath();
  }, []);

  useEffect(() => {
    path && getRestoranId();
  }, [path]);

  useEffect(() => {
    if (restoranId !== undefined) {
      getRestaurantLogo();
    }
  }, [restoranId]);

  useEffect(() => {
    if (userLoggedIn === true) {
      getSkupine();
      getArtikli();
      getRestoranJezici();
      getRestoranValute();
      getAktivanDropdownOptions();
      getSkupineDropdownOptions();
    }
  }, [userLoggedIn]);

  useEffect(() => {
    if (mainAdmin === true) getUsers();
  }, [mainAdmin]);

  useEffect(() => {
    if (shouldTableUpdate === true && userLoggedIn) getArtikli();
    setShouldTableUpdate(false);
  }, [shouldTableUpdate]);

  useEffect(() => {
    if (shouldSkupineTableUpdate === true && userLoggedIn) getSkupine();
    setShouldSkupineTableUpdate(false);
  }, [shouldSkupineTableUpdate]);

  const getPath = () => {
    setLoading(true);

    var currentPath = getAdminPath();

    currentPath && setPath(currentPath);

    setLoading(false);
  };

  const getRestoranId = async () => {
    setLoading(true);

    const data: NumberResponse = await get("/Restoran/GetIdByRoute/" + path);

    if (data) {
      setRestoranId(data.value);
    }

    setLoading(false);
  };

  const getRestaurantLogo = () => {
    setLoading(true);

    var data = getLogo(restoranId!);

    setLogo(data);

    setLoading(false);
  };

  const getRestoranJezici = async () => {
    const jeziciData: RestoranJezikResponse = await get(
      "/Restoran/GetJeziciByRestoranId/" + restoranId
    );

    if (jeziciData) {
      setRestoranJezik(jeziciData);
    }
  };

  const getRestoranValute = async () => {
    const valuteData: RestoranValutaResponse = await get(
      "/Restoran/GetValuteByRestoranId/" + restoranId
    );

    if (valuteData) {
      setRestoranValuta(valuteData);
    }

    setLoading(false);
  };

  const getSkupine = async () => {
    setLoading(true);

    const skupineData: SkupinaAdminTable[] = await get(
      "/Skupina/GetAllAdmin/" + restoranId
    );

    if (skupineData && skupineData.length > 0) setSkupine(skupineData);

    setLoading(false);
  };

  const getUsers = async () => {
    setLoading(true);

    const data: UserTable[] = await get("/User/GetAllTable/" + restoranId);

    if (data) {
      setUsers(data);
    }

    setLoading(false);
  };

  const getArtikli = async () => {
    setLoading(true);

    const artiklData: ArtiklAdminTable[] = await get(
      "/Artikl/GetAll/" + restoranId
    );

    if (artiklData && artiklData.length > 0) setArtikli(artiklData);

    setLoading(false);
  };

  const getAktivanDropdownOptions = async () => {
    const data = getAktivanModel();

    setAktivanDropdownOptions(
      data.map(
        (item: IAktivanModel): DropdownOption => ({
          id: item.aktivanId.toString(),
          name: item.displayName,
        })
      )
    );
  };

  const getSkupineDropdownOptions = async () => {
    const skupineData: SkupinaDropdown[] = await get(
      "/Skupina/GetAllDropdown/" + restoranId
    );

    if (skupineData) {
      setSkupineDropdownOptions(
        skupineData.map(
          (item: SkupinaDropdown): DropdownOption => ({
            id: item.id!.toString(),
            name: item.naziv!,
          })
        )
      );
    }
  };

  const handleArtiklDrawerChange = (
    newDrawerState: DrawerState,
    id?: number
  ) => {
    setArtiklDrawerState(newDrawerState);
    setClickedArtiklId(id);
    if (newDrawerState === DrawerState.Closed) setShouldTableUpdate(true);
  };

  const handleSuccessArtiklDrawerChange = (newDrawerState: DrawerState) => {
    setArtiklDrawerState(newDrawerState);
    if (newDrawerState === DrawerState.Closed) setShouldTableUpdate(true);
  };

  const handleCloseArtiklDrawerChange = (newDrawerState: DrawerState) => {
    setArtiklDrawerState(newDrawerState);
  };

  const handleSkupinaDrawerChange = (
    newDrawerState: DrawerState,
    id?: number
  ) => {
    setSkupinaDrawerState(newDrawerState);
    setClickedSkupinaId(id);
    if (newDrawerState === DrawerState.Closed)
      setShouldSkupineTableUpdate(true);
  };

  const handleSuccessSkupinaDrawerChange = (
    newDrawerState: DrawerState,
    id?: number
  ) => {
    setSkupinaDrawerState(newDrawerState);

    if (newDrawerState === DrawerState.Closed) {
      setShouldSkupineTableUpdate(true);
      getSkupineDropdownOptions();
    }
  };

  const handleCloseSkupinaDrawerChange = (
    newDrawerState: DrawerState,
    id?: number
  ) => {
    setSkupinaDrawerState(newDrawerState);
  };

  const handleLoginDrawerChange = (
    newDrawerState: DrawerState,
    id?: number
  ) => {
    setLoginDrawerState(newDrawerState);
  };

  const handleRegisterDrawerChange = (
    newDrawerState: DrawerState,
    id?: number
  ) => {
    setRegisterDrawerState(newDrawerState);
  };

  const handleChangePasswordDrawerChange = (
    newDrawerState: DrawerState,
    id?: number
  ) => {
    setChangePasswordDrawerState(newDrawerState);
  };

  const handleKodDrawerChange = (newDrawerState: DrawerState) => {
    setKodDrawerState(newDrawerState);
  };

  const handleLoginSuccess = (newDrawerState: DrawerState) => {
    setLoginDrawerState(newDrawerState);
    setUserLoggedIn(true);
  };

  const handleDeleteModal = (newState: boolean) => {
    setDeleteModal(newState);
  };

  const handleArtiklActiveModal = (newState: boolean) => {
    setArtiklActiveModal(newState);
  };

  const handleArtiklActiveOkModal = async (value: boolean) => {
    let formData = new FormData();

    formData.append("aktivan", value as any);
    formData.append("artiklId", clickedArtiklId as any);

    const data: BooleanResponse = await post("/Artikl/ChangeActive", formData);

    if (data.value) {
      const index = artikli!.findIndex(
        (a: ArtiklAdminTable): boolean => a.id === clickedArtiklId
      );
      artikli![index].aktivan = value;
    }

    handleArtiklActiveModal(false);
  };

  const handleSkupinaActiveModal = (newState: boolean) => {
    setSkupinaActiveModal(newState);
  };

  const handleSkupinaActiveOkModal = async (value: boolean) => {
    let formData = new FormData();

    formData.append("aktivan", value as any);
    formData.append("skupinaId", clickedSkupinaId as any);

    const data: BooleanResponse = await post("/Skupina/ChangeActive", formData);

    if (data.value) {
      const index = skupine!.findIndex(
        (a: SkupinaAdminTable): boolean => a.id === clickedSkupinaId
      );
      skupine![index].aktivan = value;
    }

    handleSkupinaActiveModal(false);
  };

  const handleDeleteUser = async () => {
    
    const data: BooleanResponse = await deleteApi(
      "/User/Delete/" + clickedUserId + "/" + restoranId,
      translations.user.errorDelete
    );

    if (data.value) {
      showSuccess(translations.user.successDelete);
      getUsers();
    }

    handleDeleteModal(false);
  };

  const getColumns = (naziv?: string) => {
    const columns: ColumnsType<ArtiklAdminTable> = [
      {
        title: naziv,
        dataIndex: "naziv",
        width: "50%",
      },
      {
        title: translations.table.aktivan,
        dataIndex: "aktivan",
        width: "25%",
        align: "center" as AlignType,
        render: (value: boolean, row: ArtiklAdminTable) => (
          <Button
            type="text"
            onClick={() => {
              setClickedArtiklId(row.id);
              handleArtiklActiveModal(true);
            }}
          >
            {value ? (
              <CheckOutlined style={{ width: "100%", color: "green" }} />
            ) : (
              <CloseOutlined style={{ width: "100%", color: "red" }} />
            )}
          </Button>
        ),
      },
      {
        title: translations.table.cijena,
        dataIndex: "cijena",
        width: "25%",
        align: "end" as AlignType,
        className: "cijenaColumn",
      },
    ];

    return columns;
  };

  const skupineColumns: ColumnsType<SkupinaAdminTable> = [
    {
      title: translations.skupineTable.naziv,
      dataIndex: "naziv",
      width: "70%",
    },
    {
      title: translations.skupineTable.aktivna,
      dataIndex: "aktivan",
      width: "30%",
      align: "center" as AlignType,
      render: (value: boolean, row: SkupinaAdminTable) => (
        <Button
          type="text"
          onClick={() => {
            setClickedSkupinaId(row.id);
            handleSkupinaActiveModal(true);
          }}
        >
          {value ? (
            <CheckOutlined style={{ width: "100%", color: "green" }} />
          ) : (
            <CloseOutlined style={{ width: "100%", color: "red" }} />
          )}
        </Button>
      ),
    },
  ];

  const userColumns: ColumnsType<UserTable> = [
    {
      title: translations.userTable.userName,
      dataIndex: "userName",
      width: "80%",
    },
    {
      title: translations.userTable.obrisi,
      width: "20%",
      align: "center",
      render: (row: UserTable) => (
        <Button
          type="text"
          onClick={() => {
            handleDeleteModal(true);
            setClickedUserId(row.id);
          }}
        >
          <DeleteOutlined style={{ width: "100%", color: "red" }} />
        </Button>
      ),
    },
  ];

  return (
    <Form className={`${path}-ant-form`}>
      {!userLoggedIn && logo && (
        <Form.Item>
          <Image
            src={logo}
            className={`${path}-logo-admin-not-logged`}
            preview={false}
          />
        </Form.Item>
      )}
      {!userLoggedIn && logo && (
        <Form.Item
          style={{ marginTop: "120px", marginLeft: "calc(50vw - 35px)" }}
        >
          <Button
            className={`${path}-admin-buttons`}
            size="large"
            onClick={() => handleLoginDrawerChange(DrawerState.Create)}
          >
            {translations.general.prijava}
          </Button>
        </Form.Item>
      )}
      {!userLoggedIn && logo && (
        <Form.Item
          style={{ marginTop: "50px", marginLeft: "calc(50vw - 50px)" }}
        >
          <Button
            className={`${path}-admin-buttons`}
            size="large"
            onClick={() => handleRegisterDrawerChange(DrawerState.Create)}
          >
            {translations.general.registracija}
          </Button>
        </Form.Item>
      )}
      {userLoggedIn && (
        <Form.Item>
          <Image
            src={logo}
            className={`${path}-logo-admin-logged`}
            preview={false}
          />
        </Form.Item>
      )}
      {(!artikli || !logo) && (
        <Spin
          size="large"
          spinning={loading}
          style={{ marginTop: "100px", marginLeft: "calc(50vw - 10px)" }}
        />
      )}
      {userLoggedIn &&
        skupine &&
        artikli &&
        skupine.map(
          (skupina: SkupinaAdminTable): ReactElement => (
            <Form.Item key={skupina.id} name={skupina.id}>
              <Table
                key={skupina.id}
                locale={getTableLocale()}
                dataSource={artikli.filter(
                  (a: ArtiklAdminTable): boolean => a.skupinaId === skupina.id
                )}
                columns={getColumns(
                  skupine.filter(
                    (s: SkupinaAdminTable): boolean => s.id === skupina.id
                  )[0].naziv
                )}
                rowKey={(record) => record.id!}
                bordered={false}
                pagination={false}
                loading={loading}
                style={{ width: "100%", marginBottom: "10px" }}
                rowClassName={(item, index) =>
                  index % 2 === 0 ? `${path}-row-light` : `${path}-row-dark`
                }
                onRow={(item): { onClick: (column: any) => void } => ({
                  onClick: (column: { target: { nodeName: string } }): void =>
                    onRowClick(column, (): void =>
                      handleArtiklDrawerChange(DrawerState.Create, item.id)
                    ),
                })}
                className={path}
              />
            </Form.Item>
          )
        )}
      {userLoggedIn && skupine && skupine.length > 0 && (
        <Form.Item>
          <Button
            style={{
              display: "block",
              marginLeft: "30px",
              textAlign: "center",
            }}
            className={`${path}-admin-buttons`}
            onClick={() => handleArtiklDrawerChange(DrawerState.Create)}
          >
            {translations.general.addArtikl}
          </Button>
        </Form.Item>
      )}
      {userLoggedIn && (
        <h4
          style={{
            textAlign: "center",
            marginTop: "60px",
            marginBottom: "20px",
          }}
          className={`${path}-admin-text`}
        >
          {translations.general.upravljanjeSKupinama}
        </h4>
      )}
      {userLoggedIn && (
        <Table
          locale={getTableLocale()}
          columns={skupineColumns}
          dataSource={skupine}
          bordered={false}
          pagination={false}
          loading={loading}
          style={{ width: "100%", marginBottom: "30px" }}
          rowKey={(item): number => item.id!}
          rowClassName={(item, index) =>
            index % 2 === 0 ? `${path}-row-light` : `${path}-row-dark`
          }
          onRow={(item): { onClick: (column: any) => void } => ({
            onClick: (column: { target: { nodeName: string } }): void =>
              onRowClick(column, (): void =>
                handleSkupinaDrawerChange(DrawerState.Create, item.id)
              ),
          })}
          className={path}
        />
      )}
      {userLoggedIn && (
        <Form.Item>
          <Button
            style={{
              display: "block",
              marginLeft: "30px",
              textAlign: "center",
            }}
            className={`${path}-admin-buttons`}
            onClick={() => handleSkupinaDrawerChange(DrawerState.Create)}
          >
            {translations.general.addSkupina}
          </Button>
        </Form.Item>
      )}
      {userLoggedIn && users && mainAdmin && (
        <h4
          style={{
            textAlign: "center",
            marginTop: "60px",
            marginBottom: "20px",
          }}
          className={`${path}-admin-text`}
        >
        {translations.general.upravljanjeKorisnicima}
        </h4>
          )}
        {userLoggedIn && mainAdmin && users && users.length > 0 && (
        <Table
          locale={getTableLocale()}
          columns={userColumns}
          dataSource={users}
          bordered={false}
          pagination={false}
          loading={loading}
          style={{ width: "100%", marginBottom: "30px" }}
          rowKey={(item): string => item.id!}
          rowClassName={(item, index) =>
            index % 2 === 0 ? `${path}-row-light` : `${path}-row-dark`
          }
          className={path}
        />
      )}
      {userLoggedIn && users && mainAdmin && (
        <Form.Item>
          <Button
            style={{
              display: "block",
              marginLeft: "30px",
              textAlign: "center",
            }}
            className={`${path}-admin-buttons`}
            onClick={() => handleKodDrawerChange(DrawerState.Create)}
          >
            {translations.general.changeKod}
          </Button>
        </Form.Item>
      )}
      {userLoggedIn && (
        <Form.Item>
          <Image
            src={logo}
            className={`${path}-logo-admin-logged`}
            preview={false}
          />
        </Form.Item>
      )}
      {userLoggedIn && (
        <Drawer
          title={translations.artikl.title}
          visible={!!artiklDrawerState}
          onClose={() => handleCloseArtiklDrawerChange(DrawerState.Closed)}
          width={DRAWER_WIDTH}
          destroyOnClose
        >
          <ArtiklForm
            onClose={() => handleCloseArtiklDrawerChange(DrawerState.Closed)}
            onSuccess={() =>
              handleSuccessArtiklDrawerChange(DrawerState.Closed)
            }
            artiklId={clickedArtiklId}
            skupineDropdownOptions={skupineDropdownOptions}
            aktivanDropdownOptions={aktivanDropdownOptions}
            restoranValuta={restoranValuta!}
            restoranJezik={restoranJezik!}
          />
        </Drawer>
      )}
      {userLoggedIn && (
        <Drawer
          title={translations.skupina.title}
          visible={!!skupinaDrawerState}
          onClose={() => handleCloseSkupinaDrawerChange(DrawerState.Closed)}
          width={DRAWER_WIDTH}
          destroyOnClose
        >
          <SkupinaForm
            onClose={() => handleCloseSkupinaDrawerChange(DrawerState.Closed)}
            onSuccess={() =>
              handleSuccessSkupinaDrawerChange(DrawerState.Closed)
            }
            skupinaId={clickedSkupinaId}
            aktivanDropdownOptions={aktivanDropdownOptions}
            restoranJezik={restoranJezik}
            restoranId={restoranId}
          />
        </Drawer>
      )}
      {userLoggedIn && mainAdmin && (
        <Drawer
          title={translations.kod.title}
          visible={!!kodDrawerState}
          onClose={() => handleKodDrawerChange(DrawerState.Closed)}
          width={DRAWER_WIDTH}
          destroyOnClose
        >
          <KodForm
            onClose={() => handleKodDrawerChange(DrawerState.Closed)}
            onSuccess={() => handleKodDrawerChange(DrawerState.Closed)}
            restoranId={restoranId}
          />
        </Drawer>
      )}
      {!userLoggedIn && logo && (
        <Drawer
          title={translations.user.loginTitle}
          visible={!!loginDrawerState}
          onClose={() => handleLoginDrawerChange(DrawerState.Closed)}
          width={DRAWER_WIDTH}
          destroyOnClose
        >
          <LoginForm
            onClose={() => handleLoginDrawerChange(DrawerState.Closed)}
            onSuccess={() => handleLoginSuccess(DrawerState.Closed)}
            onForgotPassword={() => {
              handleLoginDrawerChange(DrawerState.Closed);
              handleChangePasswordDrawerChange(DrawerState.Create);
            }}
            restoranId={restoranId}
            setMainAdmin={setMainAdmin}
          />
        </Drawer>
      )}
      {!userLoggedIn && logo && (
        <Drawer
          title={translations.user.registerTitle}
          visible={!!registerDrawerState}
          onClose={() => handleRegisterDrawerChange(DrawerState.Closed)}
          width={DRAWER_WIDTH}
          destroyOnClose
        >
          <RegisterForm
            onClose={() => handleRegisterDrawerChange(DrawerState.Closed)}
            restoranId={restoranId!}
          />
        </Drawer>
      )}
      {!userLoggedIn && logo && (
        <Drawer
          title={translations.user.changePasswordTitle}
          visible={!!changePasswordDrawerState}
          onClose={() => handleChangePasswordDrawerChange(DrawerState.Closed)}
          width={DRAWER_WIDTH}
          destroyOnClose
        >
          <ChangePasswordForm
            onClose={() => handleChangePasswordDrawerChange(DrawerState.Closed)}
            restoranId={restoranId}
          />
        </Drawer>
      )}
      {deleteModal && mainAdmin && (
        <Modal
          title={translations.user.deleteUser}
          visible={deleteModal && mainAdmin}
          onOk={(): Promise<void> => handleDeleteUser()}
          onCancel={(): void => handleDeleteModal(false)}
          okText={translations.general.modalOk}
          cancelText={translations.general.modalCancel}
          width={320}
        >
          {users &&
            translations.user.deleteUserText.replace(
              "{x}",
              users.find((u: UserTable): boolean => u.id === clickedUserId)
                ?.userName!
            )}
        </Modal>
      )}
      {artiklActiveModal && (
        <Modal
          title={translations.artikl.artiklActiveModal}
          visible={artiklActiveModal}
          closable={false}
          width={320}
          footer={[
            <Button
              key="odustani"
              onClick={(): void => handleArtiklActiveModal(false)}
            >
              {translations.general.cancel}
            </Button>,
            <Button
              key="ne"
              type="primary"
              onClick={(): Promise<void> => handleArtiklActiveOkModal(false)}
              style={{ backgroundColor: "red", borderColor: "red" }}
            >
              {translations.general.ne}
            </Button>,
            <Button
              key="da"
              type="primary"
              onClick={(): Promise<void> => handleArtiklActiveOkModal(true)}
              style={{ backgroundColor: "green", borderColor: "green" }}
            >
              {translations.general.da}
            </Button>,
          ]}
        >
          {artikli &&
            translations.artikl.artiklActiveModalText.replace(
              "{x}",
              artikli.find(
                (a: ArtiklAdminTable): boolean => a.id === clickedArtiklId
              )?.naziv!
            )}
        </Modal>
      )}
      {skupinaActiveModal && (
        <Modal
          title={translations.skupina.skupinaActiveModal}
          visible={skupinaActiveModal}
          closable={false}
          width={320}
          footer={[
            <Button
              key="odustani"
              onClick={(): void => handleSkupinaActiveModal(false)}
            >
              {translations.general.cancel}
            </Button>,
            <Button
              key="ne"
              type="primary"
              onClick={(): Promise<void> => handleSkupinaActiveOkModal(false)}
              style={{ backgroundColor: "red", borderColor: "red" }}
            >
              {translations.general.ne}
            </Button>,
            <Button
              key="da"
              type="primary"
              onClick={(): Promise<void> => handleSkupinaActiveOkModal(true)}
              style={{ backgroundColor: "green", borderColor: "green" }}
            >
              {translations.general.da}
            </Button>,
          ]}
        >
          {artikli &&
            skupine &&
            translations.skupina.skupinaActiveModalText.replace(
              "{x}",
              skupine.find(
                (a: SkupinaAdminTable): boolean => a.id === clickedSkupinaId
              )?.naziv!
            )}
        </Modal>
      )}
    </Form>
  );
};

export default MainAdmin;
