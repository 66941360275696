import React, { useState } from "react";
import { translations } from "../../config/translations";
import { post } from "../../utils/api";
import { Form, Input } from "antd";
import { formLayoutDrawer } from "../../helpers/TableHelper";
import { DrawerButtons } from "../../components/drawer/buttons/DrawerButtons";
import { showError, showSuccess } from "../../helpers/NotificationHelper";
import { BooleanResponse } from "../../core/models/response/BooleanResponse";
interface Props {
  onClose: () => void;
  restoranId: number;
}

const RegisterForm: React.FC<Props> = (props: Props) => {
  const { onClose, restoranId } = props;
  const [email, setEmail] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [confirmPassword, setConfirmPassword] = useState<string | undefined>();
  const [code, setCode] = useState<string | undefined>();

  const handleLogin = async () => {
    if (password === confirmPassword) {
      let formData = new FormData();

      formData.append("email", email!);
      formData.append("password", password!);
      formData.append("restoranId", restoranId as any);
      formData.append("kod", code!);

      const data: BooleanResponse = await post(
        "/User/Register",
        formData,
        translations.user.errorRegister
      );

      if (data.value) {
        showSuccess(translations.user.successRegister);
        onClose();
      } else {
        showError(translations.user.errorRegister);
      }
    } else {
      showError(translations.user.passwordNotEqual);
    }
  };

  return (
    <Form
      onFinish={handleLogin}
      style={{ marginLeft: "20px", marginRight: "40px" }}
      {...formLayoutDrawer}
    >
      <DrawerButtons
        onCancelAction={() => onClose()}
        disableSubmit={!email || !password || !confirmPassword || !code}
      />
      <Form.Item
        label={translations.user.email}
        rules={[
          {
            type: "email",
            message: "Neispravan email",
          },
        ]}
        style={{ marginTop: "30px" }}
      >
        <Input
          placeholder={translations.user.unesiteEmail}
          value={email}
          type="email"
          onChange={(input): void => {
            setEmail(input.target.value);
          }}
        ></Input>
      </Form.Item>
      <Form.Item label={translations.user.password}>
        <Input
          placeholder={translations.user.unesitePassword}
          value={password}
          type="password"
          onChange={(input): void => {
            setPassword(input.target.value);
          }}
        ></Input>
      </Form.Item>
      <Form.Item label={translations.user.confirmPassword}>
        <Input
          placeholder={translations.user.unesiteConfirmPassword}
          value={confirmPassword}
          type="password"
          onChange={(input): void => {
            setConfirmPassword(input.target.value);
          }}
        ></Input>
      </Form.Item>
      <Form.Item label={translations.user.kod}>
        <Input
          placeholder={translations.user.unesiteKod}
          value={code}
          onChange={(input): void => {
            setCode(input.target.value);
          }}
        ></Input>
      </Form.Item>
    </Form>
  );
};

export default RegisterForm;
