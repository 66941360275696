import { Empty } from "antd";
import { TableLocale } from "antd/lib/table/interface";
import React from "react";
import { translations } from "../config/translations";
import { DropdownOption } from "../core/models/dropdown/DropdownOption";

export const onRowClick = (
  column: { target: { nodeName: string } },
  callback: Function
): void => {
  if (column && column.target && column.target.nodeName === "TD") {
    callback();
  }
};

export const getTableLocale = (): TableLocale => ({
  emptyText: (
    <Empty
      description={translations.general.noData}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
    />
  ),
});

export const formLayoutDrawer = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

export const formLayoutMain = {
  labelCol: {
    xs: { span: 20 },
    sm: { span: 20 },
  },
  wrapperCol: {
    xs: { span: 4 },
    sm: { span: 4 },
  },
};

export const customArrayValidationHelper = (
  _rule: any,
  callback: any,
  message: string,
  entityToValidate?: DropdownOption[]
): void => {
  if (!entityToValidate || entityToValidate.length === 0) {
    callback(message);
  } else {
    callback();
  }
};

export const customStringValidationHelper = (
  _rule: any,
  callback: any,
  message: string,
  entityToValidate?: string
): void => {
  if (!entityToValidate || entityToValidate.length === 0) {
    callback(message);
  } else {
    callback();
  }
};
