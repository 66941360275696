import axios, { AxiosResponse } from "axios";
import { API_HOST } from "../config/constants";
import { showError } from "../helpers/NotificationHelper";

interface Error {
  message?: string;
  response?: AxiosResponse;
  data?: string;
}

const instance = axios.create({
  baseURL: API_HOST,
  timeout: 60000,
});

const handleError = (err: Error, customMessage?: string): any => {
  const { message, response } = err;

  if (customMessage) {
    showError(customMessage);
    return customMessage;
  }
  if (message) {
    showError(message);
    return message;
  }
  if (response) {
    showError(message);
    return response;
  }
  return err;
};

export const get = async <T>(
  url: string,
  customErrorMessage?: string
): Promise<T> => {
  const res = await instance
    .get<T>(url, {
      transformResponse: ([] as any[]).concat(
        (data: string): T => JSON.parse(data)
      ),
    })
    .catch((err): any => handleError(err, customErrorMessage));
  return res.data;
};

export const post = async <T>(
  url: string,
  data?: any,
  customErrorMessage?: string
): Promise<T> => {
  const res = await instance
    .post(url, data, {
      timeout: 60000,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((err): any => handleError(err, customErrorMessage));
  return res.data;
};

export const deleteApi = async <T>(
  url: string,
  customErrorMessage?: string
): Promise<T> => {
  const res = await instance
    .delete<T>(url, {
      transformResponse: ([] as any[]).concat(
        (data: string): T => JSON.parse(data)
      ),
    })
    .catch((err): any => handleError(err, customErrorMessage));
  return res.data;
};

export const put = async <T>(
  url: string,
  data?: any,
  customErrorMessage?: string
): Promise<T> => {
  const res = await instance
    .put(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((err): any => handleError(err, customErrorMessage));
  return res.data;
};
