const routes = {
  ROUTE_HOME: "/",

  ROUTE_POLOVANEC: "/izletistePolovanec",

  ROUTE_POLOVANEC_ADMIN: "/izletistePolovanec/admin",

  ROUTE_TEST: "/test",

  ROUTE_TEST_ADMIN: "/test/admin",

  ROUTE_MAKSIMIR: "/restoranMaksimir",

  ROUTE_MAKSIMIR_ADMIN: "/restoranMaksimir/admin",

  ROUTE_SHOCK_BAR: "/shockBar",

  ROUTE_SHOCK_BAR_ADMIN: "/shockBar/admin",
};

export default routes;
