import React from 'react';
import { SyncOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import { translations } from '../../../config/translations';

interface Props {
    isSaving?: boolean;
    isFormDirty?: boolean;
    onSubmitAction?: () => void;
    onEditAction?: () => void;
    onCancelAction: () => void;
    onDeleteAction?: () => void;
    loadingText?: string;
    submitText?: string;
    editText?: string;
    deleteText?: string;
    cancelText?: string;
    disableSubmit?: boolean;
    disableEdit?: boolean;
    isEdit?: boolean;
}

export const DrawerButtons: React.FC<Props> = (props: Props) => {
    const {
        isSaving,
        isFormDirty,
        onCancelAction,
        onSubmitAction,
        onEditAction,
        onDeleteAction,
        submitText,
        editText,
        deleteText,
        loadingText,
        cancelText,
        disableSubmit,
        disableEdit,
        isEdit
    } = props;

    return (
        <Row style={{ height: 50, display: 'flow-root' }}>
            <div style={{ float: 'right' }}>
                <Button type="default" style={{ width: 80 }} onClick={onCancelAction}>
                    {cancelText || translations.general.cancel}
                </Button>
                {!isEdit && <Button
                    type="primary"
                    style={{
                        marginLeft: 10,
                        width: 80,
                    }}
                    htmlType="submit"
                    disabled={(isFormDirty !== undefined && !isFormDirty) || disableSubmit}
                    onClick={onSubmitAction}
                >
                    {isSaving && <SyncOutlined spin />}
                    {isSaving
                        ? loadingText || submitText || translations.general.save
                        : submitText || translations.general.save}
                </Button>}
                {isEdit && <Button
                    type="primary"
                    danger
                    style={{
                        marginLeft: 10,
                        width: 80,
                    }}
                    htmlType="button"
                    disabled={(isFormDirty !== undefined && !isFormDirty) || disableEdit}
                    onClick={onDeleteAction}

                >
                    {isSaving && <SyncOutlined spin />}
                    {isSaving
                        ? loadingText || deleteText || translations.general.delete
                        : deleteText || translations.general.delete}
                </Button>}
                {isEdit && <Button
                    type="primary"
                    style={{
                        marginLeft: 10,
                        width: 80,
                    }}
                    htmlType="submit"
                    disabled={(isFormDirty !== undefined && !isFormDirty) || disableEdit}
                    onClick={onEditAction}

                >
                    {isSaving && <SyncOutlined spin />}
                    {isSaving
                        ? loadingText || editText || translations.general.edit
                        : editText || translations.general.edit}
                </Button>}

            </div>
        </Row>
    );
};
