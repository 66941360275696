import logo1 from "../images/polovanec_logo.png";
import logo2 from "../images/logo.png";
import logo3 from "../images/maksimir_logo.png";
import logo4 from "../images/shockbar_logo.png";

export const getLogo = (restoranId: number): string => {
  switch (restoranId) {
    case 1:
      return logo1;
    case 2:
      return logo2;
    case 3:
      return logo3;
    case 4:
      return logo4;
    default:
      return logo2;
  }
};
