/* eslint-disable react-hooks/exhaustive-deps */
import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { DropdownOption } from '../../core/models/dropdown/DropdownOption';

const { Option } = Select;

export interface Props {
    getOptions?: (value: string) => Promise<DropdownOption[]>;
    getOptionsFrontend?: (value: string) => DropdownOption[];
    placeholder?: React.ReactNode;
    confirmDirty?: (option: DropdownOption[], moduleId?: string, isModule?: boolean) => void;
    style?: React.CSSProperties;
    initialValues?: DropdownOption[];
    disabled?: boolean;
    mode?: 'multiple' | 'tags';
    className?: string;
    optionClassName?: string;
    prefixClassName?: string;
    frontendId?: string;
    isAllowedToClear?: boolean;
    filteredOptions?: string;
    isInitialFocused?: boolean;
    objectDisplayKey?: keyof DropdownOption;
    numberOfCharMinSearch?: number;
    minDropdownWidth?: number;
}

let timeout: any;
let currentValue: string;

const fetch = (
    value: string,
    getOptions: (value: string) => Promise<DropdownOption[]>,
    callback: (data: DropdownOption[]) => void
) => {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;

    async function fake() {
        const data = await getOptions(value);
        if (currentValue === value) {
            callback(data);
        }
    }

    timeout = setTimeout(fake, 300);
}

export const AutocompleteDropdown: React.FC<Props> = (props: Props) => {
    const [autocompleteOptions, setAutocompleteOptions] = useState<DropdownOption[]>([]);
    const { className, numberOfCharMinSearch, optionClassName, prefixClassName, filteredOptions, placeholder, isAllowedToClear, isInitialFocused, minDropdownWidth, style, disabled, mode, initialValues, frontendId, objectDisplayKey, confirmDirty, getOptions, getOptionsFrontend } = props;

    useEffect((): void => {
        handleSearch('');
        if (initialValues) {
            setAutocompleteOptions([...initialValues]);
        }
    }, []);

    useEffect((): void => {
        handleSearch('');
    }, [initialValues]);

    const handleOnChange = (values: string[] | string | number) => {

        const options: DropdownOption[] = [];

        if (values) {
            // single choice mode
            if (typeof values === 'string' || typeof values === 'number') {
                addToOptionsList(values.toString(), options);
                // multiple select mode
            } else {
                values.forEach((value: string) => {
                    addToOptionsList(value.toString(), options);
                });
            }
        }

        if (confirmDirty) {
            confirmDirty(options);
        }
    };

    const addToOptionsList = (value: string, options: DropdownOption[]) => {
        let option = autocompleteOptions.find(
            (d: DropdownOption): boolean => d.id.toString() === value
        );

        if (!option && initialValues) {
            option = initialValues.find((d: DropdownOption): boolean => d.id.toString() === value);
        }

        if (option) {
            options.push({ ...option });
        } else if (frontendId) {
            options.push({
                id: frontendId,
                name: value,
            });
        }
    };

    const handleSearch = (value?: string) => {

        // frontend filtering
        if (getOptionsFrontend) {
            if (numberOfCharMinSearch && value && value.length >= numberOfCharMinSearch) {
                setAutocompleteOptions(getOptionsFrontend(value || ''));
            } else if (!numberOfCharMinSearch) {
                setAutocompleteOptions(getOptionsFrontend(value || ''));
            }
            // BE filtering
        } else if (
            numberOfCharMinSearch &&
            value &&
            value.length >= numberOfCharMinSearch &&
            getOptions
        ) {
            fetch(value, getOptions, (data: DropdownOption[]) =>
                setAutocompleteOptions(data ? [...data] : [])
            );
        } else if (!numberOfCharMinSearch && value && value.length >= 2 && getOptions) {
            fetch(value, getOptions, (data: DropdownOption[]) =>
                setAutocompleteOptions(data ? [...data] : [])
            );
        }
    };

    const options = autocompleteOptions
        ? autocompleteOptions.map((d: DropdownOption): React.ReactElement => {
            const selectedValue =
                initialValues && initialValues.find((iv): boolean => iv.id === d.id);
            const disabledValue = selectedValue !== undefined || false;
            let displayValue = d.name;
            if (objectDisplayKey && d[objectDisplayKey]) {
                displayValue = d[objectDisplayKey]!.toString();
            }
            return (
                <Option
                    value={d.id}
                    key={`addProductToGroup-${d.id}`}
                    style={{
                        display: disabledValue ? 'none' : 'block',
                    }}
                    className={optionClassName}
                    disabled={d.disabled}
                    title={d.descriptionalMessage || displayValue}
                >
                    {displayValue}
                </Option>
            );
        }) : [];

    return (
        <Select
            ref={(input: any): void | false => isInitialFocused && input && input.focus()}
            showSearch
            prefixCls={prefixClassName}
            className={className}
            optionFilterProp="children"
            filterOption={filteredOptions ? undefined : true}
            placeholder={placeholder}
            mode={mode}
            onSearch={handleSearch}
            onFocus={() => handleSearch('')}
            onChange={handleOnChange}
            notFoundContent="There are no selectable options."
            defaultActiveFirstOption={false}
            value={
                initialValues &&
                initialValues.map((d: DropdownOption): string => d.name.toString())
            }
            style={style}
            disabled={disabled}
            allowClear={isAllowedToClear || false}
            dropdownStyle={{ minWidth: minDropdownWidth }}
        >
            {options}
        </Select>
    );
}
