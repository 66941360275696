/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { translations } from "../../config/translations";
import { post, get, deleteApi } from "../../utils/api";
import { Form, Input, Modal, InputNumber } from "antd";
import { formLayoutDrawer } from "../../helpers/TableHelper";
import { DrawerButtons } from "../../components/drawer/buttons/DrawerButtons";
import { showSuccess } from "../../helpers/NotificationHelper";
import { DropdownOption } from "../../core/models/dropdown/DropdownOption";
import { AktivanEnum } from "../../core/models/enum/enum";
import { AutocompleteDropdown } from "../../components/autocomplete-dropdown/AutocompleteDropdown";
import {
  filterDropdownOptions,
  confirmDropdownHelper,
} from "../../helpers/AutocompleteDropdownHelper";
import { SkupinaEditResponse } from "../../core/models/response/SkupinaEditResponse";
import { SkupinaEditRequest } from "../../core/models/request/SkupinaEditRequest";
import { RestoranJezikResponse } from "../../core/models/response/RestoranJezikResponse";
import { customArrayValidationHelper } from "../../helpers/TableHelper";
import { BooleanResponse } from "../../core/models/response/BooleanResponse";

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  skupinaId?: number;
  aktivanDropdownOptions?: DropdownOption[];
  restoranJezik?: RestoranJezikResponse;
  restoranId?: number;
}

const SkupinaForm: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    onSuccess,
    skupinaId,
    aktivanDropdownOptions,
    restoranJezik,
    restoranId,
  } = props;
  const [naziv, setNaziv] = useState<string | undefined>();
  const [nazivEng, setNazivEng] = useState<string | undefined>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<string>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedAktivan, setSelectedAktivan] = useState<
    DropdownOption[] | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);

  const { TextArea } = Input;

  useEffect(() => {
    if (skupinaId) {
      setIsEdit(true);
      getSkupina();
    }
  }, [skupinaId]);

  const getSkupina = async () => {
    setLoading(true);

    const data: SkupinaEditResponse = await get(
      "/Skupina/GetById/" + skupinaId,
      translations.skupina.errorGet
    );

    setNaziv(data.naziv);
    setNazivEng(data.nazivEng);
    setSortOrder(data.sortOrder);
    setSelectedAktivan(
      getAktivan(data.aktivan ? AktivanEnum.DA : AktivanEnum.NE)
    );

    setLoading(false);
  };

  const getAktivan = (id: AktivanEnum): DropdownOption[] => {
    const aktivan: DropdownOption[] = aktivanDropdownOptions!.filter(
      (o: DropdownOption): boolean => Number(o.id!) === id!
    );

    return aktivan;
  };

  const handleSave = async () => {
    const skupina: SkupinaEditRequest = {
      id: skupinaId,
      naziv,
      nazivEng,
      restoranId,
      sortOrder,
      aktivan: Number(selectedAktivan![0].id) === AktivanEnum.DA ? true : false,
    };

    if (!isEdit) {
      const data: BooleanResponse = await post(
        "/Skupina/Save",
        skupina,
        translations.skupina.errorCreate
      );

      if (data.value) {
        showSuccess(translations.skupina.successCreate);
        onSuccess();
      }
    } else {
      const data: BooleanResponse = await post(
        "/Skupina/Edit",
        skupina,
        translations.skupina.errorEdit
      );

      if (data.value) {
        showSuccess(translations.skupina.successEdit);
        onSuccess();
      }
    }
  };

  const confirmAktivan = (option: DropdownOption[]) => {
    const selectedAktivan = confirmDropdownHelper(option);

    setSelectedAktivan(selectedAktivan);
  };

  const handleDeleteModal = (newState: boolean) => {
    setShowDeleteModal(newState);
  };

  const deleteSkupina = async () => {
    const data: BooleanResponse = await deleteApi(
      "/Skupina/Delete/" + skupinaId,
      translations.skupina.errorDelete
    );

    if (data.value) {
      showSuccess(translations.skupina.successDelete);
      onSuccess();
    }
  };

  return (
    <>
      {((!loading && skupinaId && restoranJezik) ||
        (!skupinaId && restoranJezik)) && (
        <Form
          onFinish={handleSave}
          style={{ marginLeft: "20px", marginRight: "40px" }}
          {...formLayoutDrawer}
        >
          <DrawerButtons
            onCancelAction={() => onClose()}
            isEdit={isEdit}
            onDeleteAction={() => handleDeleteModal(true)}
          />
          <Form.Item
            name="naziv"
            label={translations.skupina.naziv}
            initialValue={naziv}
            rules={[
              {
                required: true,
                message: translations.general.requiredField,
              },
            ]}
          >
            <TextArea
              autoSize={{ minRows: 1 }}
              placeholder={translations.skupina.unesiteNaziv}
              value={naziv}
              onChange={(input): void => {
                setNaziv(
                  input.target.value.length > 0 ? input.target.value : undefined
                );
              }}
            ></TextArea>
          </Form.Item>
          {restoranJezik!.eng && (
            <Form.Item
              name="nazivEng"
              label={translations.skupina.nazivEng}
              initialValue={nazivEng}
              rules={[
                {
                  required: restoranJezik!.eng,
                  message: translations.general.requiredField,
                },
              ]}
            >
              <TextArea
                autoSize={{ minRows: 1 }}
                placeholder={translations.skupina.unesiteNazivEng}
                value={nazivEng}
                onChange={(input): void => {
                  setNazivEng(
                    input.target.value.length > 0
                      ? input.target.value
                      : undefined
                  );
                }}
              ></TextArea>
            </Form.Item>
          )}
          {isEdit && (
            <Form.Item
              name="sortOrder"
              label={translations.skupina.sortOrder}
              initialValue={sortOrder}
              rules={[
                {
                  required: isEdit,
                  message: translations.general.requiredField,
                },
              ]}
            >
              <InputNumber
                min={"1"}
                value={sortOrder}
                onChange={(input): void => {
                  setSortOrder(input);
                }}
              ></InputNumber>
            </Form.Item>
          )}
          <Form.Item
            name="aktivanDropdown"
            label={translations.skupina.aktivna}
            rules={[
              {
                required: true,
                validator: (_rule: any, value: any, callback: any): void =>
                  customArrayValidationHelper(
                    _rule,
                    callback,
                    translations.general.requiredField,
                    selectedAktivan
                  ),
              },
            ]}
          >
            <AutocompleteDropdown
              placeholder={translations.skupina.unesiteAktivna}
              getOptionsFrontend={(value: string): DropdownOption[] =>
                filterDropdownOptions(value, aktivanDropdownOptions!)
              }
              initialValues={selectedAktivan}
              confirmDirty={confirmAktivan}
            />
          </Form.Item>
          {isEdit && (
            <Modal
              title={translations.skupina.deleteModal}
              visible={showDeleteModal}
              onOk={deleteSkupina}
              onCancel={(): void => handleDeleteModal(false)}
              okText={translations.general.modalOk}
              cancelText={translations.general.modalCancel}
              width={320}
            >
              {translations.skupina.deleteModalText}
            </Modal>
          )}
        </Form>
      )}
    </>
  );
};

export default SkupinaForm;
