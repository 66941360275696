export const translations = {
  general: {
    dashboard: "Cjenik",
    polovanec: "Izletište Polovanec",
    noData: "Nema spremnih jela",
    save: "Spremi",
    cancel: "Odustani",
    da: "Da",
    ne: "Ne",
    edit: "Uredi",
    delete: "Obriši",
    artikl: "Artikl",
    addArtikl: "Dodaj proizvod",
    addSkupina: "Dodaj skupinu",
    modalOk: "Obriši",
    modalCancel: "Odustani",
    homeMessage: "Molimo, skenirajte QR kod kako biste vidjeli jelovnik!",
    prijava: "Prijava",
    registracija: "Registracija",
    jezik: "Jezik",
    jezikEng: "Language",
    valuta: "Valuta",
    valutaEng: "Currency",
    changeKod: "Promijeni registracijski kod",
    iconModalTitle: "Naručite svoj jelovnik",
    iconModalText:
      "Želite li jelovnik u Vašem restoranu, kontaktirajte nas na realtimemenu@gmail.com.",
    requiredField: "Obavezno polje",
    upravljanjeSKupinama: "Upravljanje skupinama aplikacije",
    upravljanjeKorisnicima: "Upravljanje korisnicima aplikacije"
  },
  artikl: {
    title: "Proizvod",
    errorGet: "Neuspješno dohvaćanje artikla",
    naziv: "Naziv",
    aktivan: "Aktivan",
    cijenaHRK: "Cijena HRK",
    sortOrder: "Poredak",
    unesiteNaziv: "Unesite naziv",
    unesiteAktivan: "Odaberite",
    unesiteCijenaHRK: "Unesite cijenu u HRK",
    successCreate: "Uspješno kreiranje proizvoda",
    successEdit: "Uspješno uređivanje proizvoda",
    successDelete: "Uspješno brisanje proizvoda",
    errorCreate: "Nespješno kreiranje proizvoda",
    errorEdit: "Nespješno uređivanje proizvoda",
    errorDelete: "Nespješno brisanje proizvoda",
    deleteModal: "Obriši",
    deleteModalText: "Jeste li sigurni da želite obrisati proizvod?",
    skupina: "Skupina",
    unesiteSkupina: "Odaberite skupinu",
    nazivEng: "Naziv eng.",
    cijenaEUR: "Cijena EUR",
    opis: "Opis",
    opisEng: "Opis eng.",
    unesiteNazivEng: "Unesite naziv eng.",
    unesiteCijenaEUR: "Unesite cijenu u EUR",
    unesiteOpis: "Unesite opis",
    unesiteOpisEng: "Unesite opis eng.",
    artiklActiveModal: "Aktivan",
    artiklActiveModalText: "{x}",
  },
  skupina: {
    title: "Skupina",
    errorGet: "Neuspješno dohvaćanje skupine",
    naziv: "Naziv",
    unesiteNaziv: "Unesite naziv",
    nazivEng: "Naziv eng.",
    unesiteNazivEng: "Unesite naziv eng.",
    aktivna: "Aktivna",
    sortOrder: "Poredak",
    unesiteAktivna: "Odaberite",
    successCreate: "Uspješno kreiranje skupine",
    successEdit: "Uspješno uređivanje skupine",
    successDelete: "Uspješno brisanje skupine",
    errorCreate: "Nespješno kreiranje skupine",
    errorEdit: "Nespješno uređivanje skupine",
    errorDelete: "Nespješno brisanje skupine",
    deleteModal: "Obriši",
    deleteModalText: "Jeste li sigurni da želite obrisati skupinu?",
    skupinaActiveModal: "Aktivna",
    skupinaActiveModalText: "{x}",
  },
  table: {
    naziv: "Naziv",
    aktivan: "Aktivan",
    cijena: "Cijena",
    sortOrder: "Poredak",
  },
  skupineTable: {
    naziv: "Naziv",
    aktivna: "Aktivna",
    poredak: "Poredak",
  },
  userTable: {
    userName: "Email",
    obrisi: "Obriši",
  },
  user: {
    email: "Email",
    unesiteEmail: "Unesite email",
    password: "Lozinka",
    unesitePassword: "Unesite lozinku",
    confirmPassword: "Ponovite lozinku",
    newPassword: "Nova lozinka",
    unesiteNewPassword: "Unesite novu lozinku",
    confirmNewPassword: "Ponovite novu lozinku",
    unesiteConfirmPassword: "Unesite ponovljenu lozinku",
    unesiteConfirmNewPassword: "Unesite ponovljenu novu lozinku",
    successLogin: "Uspješna prijava",
    successRegister: "Uspješna registracija",
    successChangePassword: "Uspješna promjena lozinke",
    errorLogin: "Neuspješna prijava",
    errorRegister: "Neuspješna registracija",
    errorChangePassword: "Neuspješna promjena lozinke",
    loginTitle: "Prijava",
    registerTitle: "Registracija",
    changePasswordTitle: "Promjena lozinke",
    passwordNotEqual: "Lozinke nisu jednake",
    kod: "Kod",
    unesiteKod: "Unesite kod",
    successDelete: "Korisnik uspješno obrisan",
    errorDelete: "Neuspješno brisanje korisnika",
    deleteUser: "Obriši korisnika",
    deleteUserText: "Jeste li sigurni da želite obrisati korisnika {x}?",
    forgotPassword: "Zaboravili ste lozinku?",
  },
  kod: {
    title: "Registracijski kod",
    kod: "Kod",
    unesiteKod: "Unesite kod",
    errorEdit: "Neuspješna promjena koda",
    successEdit: "Kod uspješno promijenjen",
    errorGet: "Neuspješno dohvaćanje koda",
    kodValidator: "Kod mora imati minimalno 6 znamenaka",
  },
};
