import { AktivanEnum, JezikEnum, ValutaEnum } from "../core/models/enum/enum";
import { IAktivanModel } from "../core/models/enum/IAktivanModel";
import { IJezikModel } from "../core/models/enum/IJezikModel";
import { IValutaModel } from "../core/models/enum/IValutaModel";

export const getAktivanModel = (): IAktivanModel[] => {
  let result: IAktivanModel[] = [
    {
      aktivanId: AktivanEnum.NE,
      displayName: "Ne",
    },
    {
      aktivanId: AktivanEnum.DA,
      displayName: "Da",
    },
  ];

  return result;
};

export const getJezikModel = (): IJezikModel[] => {
  let result: IJezikModel[] = [
    {
      jezikId: JezikEnum.HRV,
      displayName: "HRV",
    },
    {
      jezikId: JezikEnum.ENG,
      displayName: "ENG",
    },
  ];

  return result;
};

export const getValutaModel = (): IValutaModel[] => {
  let result: IValutaModel[] = [
    {
      valutaId: ValutaEnum.HRK,
      displayName: "HRK",
    },
    {
      valutaId: ValutaEnum.EUR,
      displayName: "EUR",
    },
  ];

  return result;
};
