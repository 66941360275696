import React, { useState } from "react";
import { translations } from "../../config/translations";
import { post } from "../../utils/api";
import { Form, Input } from "antd";
import { formLayoutDrawer } from "../../helpers/TableHelper";
import { DrawerButtons } from "../../components/drawer/buttons/DrawerButtons";
import { showError, showSuccess } from "../../helpers/NotificationHelper";
import { BooleanResponse } from "../../core/models/response/BooleanResponse";

interface Props {
  onClose: () => void;
  restoranId?: number;
}

const ChangePasswordForm: React.FC<Props> = (props: Props) => {
  const { onClose, restoranId } = props;
  const [email, setEmail] = useState<string | undefined>();
  const [newPassword, setNewPassword] = useState<string | undefined>();
  const [confirmNewPassword, setConfirmNewPassword] = useState<
    string | undefined
  >();
  const [kod, setKod] = useState<string | undefined>();

  const handleChangePassword = async () => {
    if (newPassword === confirmNewPassword) {
      let formData = new FormData();

      formData.append("email", email!);
      formData.append("password", newPassword!);
      formData.append("restoranId", restoranId as any);
      formData.append("kod", kod!);

      const data: BooleanResponse = await post(
        "/User/ChangePassword",
        formData,
        translations.user.errorChangePassword
      );

      if (data.value) {
        showSuccess(translations.user.successChangePassword);
        onClose();
      } else {
        showError(translations.user.errorChangePassword);
      }
    } else {
      showError(translations.user.passwordNotEqual);
    }
  };

  return (
    <Form
      onFinish={handleChangePassword}
      style={{ marginLeft: "20px", marginRight: "40px" }}
      {...formLayoutDrawer}
    >
      <DrawerButtons
        onCancelAction={() => onClose()}
        disableSubmit={!email || !newPassword || !confirmNewPassword || !kod}
      />
      <Form.Item
        label={translations.user.email}
        rules={[
          {
            type: "email",
            message: "Neispravan email",
          },
        ]}
        style={{ marginTop: "30px" }}
      >
        <Input
          placeholder={translations.user.unesiteEmail}
          value={email}
          type="email"
          onChange={(input): void => {
            setEmail(input.target.value);
          }}
        ></Input>
      </Form.Item>
      <Form.Item label={translations.user.newPassword}>
        <Input
          placeholder={translations.user.unesiteNewPassword}
          value={newPassword}
          type="password"
          onChange={(input): void => {
            setNewPassword(input.target.value);
          }}
        ></Input>
      </Form.Item>
      <Form.Item label={translations.user.confirmNewPassword}>
        <Input
          placeholder={translations.user.unesiteConfirmNewPassword}
          value={confirmNewPassword}
          type="password"
          onChange={(input): void => {
            setConfirmNewPassword(input.target.value);
          }}
        ></Input>
      </Form.Item>
      <Form.Item label={translations.user.kod}>
        <Input
          placeholder={translations.user.unesiteKod}
          value={kod}
          onChange={(input): void => {
            setKod(input.target.value);
          }}
        ></Input>
      </Form.Item>
    </Form>
  );
};

export default ChangePasswordForm;
