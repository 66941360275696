export enum DrawerState {
  Closed = 0,
  Create = 1,
}

export enum AktivanEnum {
  NE = 0,
  DA = 1,
}

export enum JezikEnum {
  HRV = 1,
  ENG = 2,
}

export enum ValutaEnum {
  HRK = 1,
  EUR = 2,
}

export enum RestoranEnum {
  IZLETISTE_POLOVANEC = 1,
  TEST = 2,
  MAKSIMIR = 3,
  SHOCK_BAR = 4,
}
