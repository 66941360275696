
export const kodValidationHelper = (
    _rule: any,
    callback: any,
    message: string,
    entityToValidate?: string
): void => {
    if (!entityToValidate || entityToValidate.length < 6) {
        callback(message);
    } else {
        callback();
    }
};