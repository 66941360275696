import { message } from 'antd';

export const showSuccess = (msg: string) => {
    message.success(msg);
};

export const showError = (msg: any) => {
    if (typeof msg === 'string') {
        message.error(msg);
    } else if (msg.message) {
        message.error(msg.message);
    } else {
        message.error('Nepoznata greška.');
    }
};