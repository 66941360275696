import React, { useState } from "react";
import { Form, Modal } from "antd";
import { translations } from "../config/translations";
import { Image } from "antd";
import logo from "../images/logo500.png";

const Home: React.FC = () => {
  const [imageModal, setImageModal] = useState<boolean>(false);

  return (
    <Form style={{ width: "100vw", height: "100vh", backgroundColor: "white" }}>
      <Form.Item>
        <Image
          src={logo}
          style={{
            width: "40vw",
            marginLeft: "30vw",
            marginTop: "150px",
            marginBottom: "50px",
            marginRight: "30vw",
            height: "40vw",
          }}
          onClick={() => setImageModal(true)}
          preview={false}
        />
      </Form.Item>
      <h4
        style={{
          textAlign: "center",
          marginLeft: "50px",
          marginRight: "50px",
          marginBottom: "200px",
        }}
      >
        {translations.general.homeMessage}
      </h4>
      {imageModal && (
        <Modal
          title={translations.general.iconModalTitle}
          visible={imageModal}
          footer={null}
          onCancel={() => setImageModal(false)}
          width={320}
        >
          {translations.general.iconModalText}
        </Modal>
      )}
    </Form>
  );
};

export default Home;
