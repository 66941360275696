import { DropdownOption } from '../core/models/dropdown/DropdownOption';

export const filterDropdownOptions = (
    value: string,
    entitiesToSearch: DropdownOption[]
): DropdownOption[] => {
    const options: DropdownOption[] = [];
    entitiesToSearch.forEach((entity: DropdownOption) => {
        if (entity.name.toLowerCase().includes(value.toLowerCase())) {
            options.push(entity);
        }
    });
    return options;
};

export const confirmDropdownHelper = (option: DropdownOption[]): DropdownOption[] | undefined => {
    return option && option.length > 0 ? option : undefined;
};